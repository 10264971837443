import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faChartPie, faCity, faGamepad, faLaptopCode,
  faMoneyBillWave, faShoppingCart, faUsers
  } from '@fortawesome/free-solid-svg-icons';
import 'assets/scss/komsek.scss';

import SEO from 'components/seo';
import Header from 'components/header';
import Footer from 'components/footer';

const FieldsPage = ({ location }) => {
  const fields = {
    economy: {
      icon: faMoneyBillWave,
      title: 'Ekonomika',
      body: (
        <>
          <p>Cílem je vytvoření standardů a společného účetního centra pro více nemocnic, ale zajišťujeme i společnosti mimo zdravotnictví.</p>
          <p>Zde bude ve spolupráci s kmenovými zaměstnanci vybudován standard účetních postupů a jejich zapojení na zpracování dat.</p>
          <p>Budou zavedeny společně nástroje pro tok dokumentů, schvalování a účtování.</p>
          <p>Vytvoření reportingu a controlingových sestav. Tyto budou zasílány managementu a uloženy do datového skladu.</p>
          <p>Bude využíván jeden systém a ten bude mít více agend &ndash; snazší údržby, nižší náklady a rychlejší nasazení vylepšení a nových nástrojů.</p>
        </>
      ),
    },
    controlling: {
      icon: faGamepad,
      title: 'Controlling',
      body: (
        <>
          <p>„Controlling je souhrn úloh, jejichž předmětem je zajištění informací a koordinace řízení podniku k optimálnímu dosažení všech cílů podniku.“</p>
          <p>
            <strong className="h6">Jednodušší controlling</strong>
            Pomůžeme vám zautomatizovat rutinní činnosti jako je sběr a úprava dat, reporting, kalkulace a alokace nákladů nebo konsolidace plánů v Excelu. Ušetřený čas pak budete moct využít k tomu, co je opravdu důležité &ndash; analýze finančních ukazatelů, důvodů odchylek od plánu, optimalizaci cash-flow, návrhu přesnějších alokačních modelů, nebo odpočinku či starosti o rodinu.
          </p>
          <p>
            <strong className="h6">Plánování a forecasting</strong>
            Naše řešení umožňuje snadnou tvorbu plánovacích modelů provázaných od vstupních formulářů po výsledovku. Plány je možné automaticky nahrávat daty z informačního systému, verzovat,  hromadně modifikovat a kliknutím zpřístupnit uživatelům. Následně můžete monitorovat průběh plánování, akceptovat, odmítat a komentovat hodnoty bez nutnosti manuálního spojování tabulek.
          </p>
          <p>
            <strong className="h6">Řízení ziskovosti</strong>
            Ziskovost zákazníků, zakázek a produktů je pro fungování firmy zásadní. Manuálně kalkulovat a alokovat náklady je však zdlouhavé a při velkém objemu dat často nemožné. Umožníme vám soustředit se na návrh alokačních pravidel a samotné zpracování zautomatizujeme. Můžete tak denně monitorovat faktory ovlivňující ziskovost a flexibilně reagovat na změny.
          </p>
          <p>
            <strong className="h6">Reporting a analýza finančních dat</strong>
            Manuální vytváření reportů je časově náročné, obzvláště v případě více informačních systémů nebo velkých objemů dat. Dobře postavené Business Intelligence řešení zajišťuje propojení finančních, prodejních, výrobních, marketingových a provozních informací, jejich jednoduchou analýzu a automatickou distribuci reportů. Můžete tak rychle reagovat na hrozící problémy a identifikovat zajímavé příležitosti.
          </p>
        </>
      ),
    },
    analysis: {
      icon: faChartPie,
      title: <>Analýza <abbr className="tooltip" data-tooltip="zdravotní pojišťovna">ZP</abbr></>,
      body: (
        <>
          <p>Vzhledem k neustálým změnám úhradových mechanismů, složitosti jejich sledování a nutnosti regulovat produkci v určených koridorech jsme pro Vás připravili systém hlídání produkce, její optimalizaci a pravidelný reporting včetně doporučení.</p>
          <p>Budeme hlídat proskripční limity i úroveň vyžádané péče, tak aby nedocházelo k překročení limitů nastavených pojišťovnou a z toho vyplývající regulační srážce. Pro své klienty udržujeme elektronickou komunikaci s pojišťovnou, uzavírku vyúčtování a odeslání dávek na pojišťovnu. Budeme spravovat elektronickou komunikaci přes portály zdravotních pojišťoven.</p>
          <p>Zpracujeme pro své klienty roční vyúčtování, doporučíme strategii vyjednávání s pojišťovnou. Výkonnost bude maximalizována na základě pravidelných doporučení a za Vaši práci dostanete zaslouženou odměnu.</p>
        </>
      ),
    },
    management: {
      icon: faCity,
      title: 'Facility management',
      body: (
        <>
          <ul>
            <li>Zavedeme společnou databázi přístrojů a zařízení</li>
            <li>Vytvoříme elektronickou evidenci <abbr className="tooltip text-center" data-tooltip={'bezpečnostně technická kontrola\nzdravotního prostředku'}>BTK</abbr>, Revizí a oprav</li>
            <li>Elektronicky povedeme databázi návodů, předávacích protokolů, školení a dokladů o nákupu</li>
            <li>Zavedeme online trénink &ndash; školení bezpečnosti, školení řidičů a další školení dle plánu, který vypracujeme v souladu s platnou legislativou</li>
            <li>Budeme optimalizovat objednávání <abbr className="tooltip text-center" data-tooltip={'bezpečnostně technická kontrola\nzdravotního prostředku'}>BTK</abbr>, oprav a pravidelné údržby</li>
            <li>Specialista na přístroje bude schopen podat doporučení lékařům a technickým pracovníkům</li>
          </ul>
        </>
      ),
    },
    it: {
      icon: faLaptopCode,
      title: 'IT a aplikační podpora',
      body: (
        <>
          <p>Všechny vyjmenované aplikace budou provozovány na terminálovém serveru; budou na serveru a nebude nutné investovat do obnovy PC.</p>
          <p>Centrální implementace změn umožní možnost mít pravidelně nejnovější nástroje a je schopna okamžitě řešit problémy.</p>
          <ul>
            <li>Úspora na licencích a vyšší stabilita systému</li>
            <li>Podpora externích zařízení (čtečky, tiskárny, terminály)</li>
            <li>V neposlední řadě úspora nákladů díky společné správě aplikací, dat a společném zavádění změn systému.</li>
          </ul>
          <p>Máte možnost získat vysoce kvalifikovaného pracovníka za jenom část nákladů oproti provádění těchto služeb vlastními pracovníky.</p>
        </>
      ),
    },
    personnel: {
      icon: faUsers,
      title: 'Mzdy a personalistika',
      body: (
        <>
          <p>SW mzdy pomůže nastavit zpracování mezd na daleko vyšší úroveň. Každý zaměstnanec bude mít svůj portál, kde bude schopen udržovat profil &ndash; vkládání dokladů pro slevy na dani, udržování svých dat jako účet, bydliště, svěřené pomůcky plány školení, plán školení atd.</p>
          <p>Portál umožní tyto hlavní činnosti k zajištění provozu společnosti &ndash; vyplňování docházky, možnost napojení docházkového systému, plánování směn a žádosti o dovolené. Připravíme také reporting pro UZIS a ostatní organizace.</p>
          <p>Speciální přehledy pro management &ndash; přesčasy, průměrné mzdy i po skupinách zaměstnanců, čerpání dovolených, alokování mzdových nákladů na nákladová střediska a mnoho dalšího.</p>
        </>
      ),
    },
    marketing: {
      icon: faBullhorn,
      title: 'Marketing a podpora prodeje',
      body: (
        <>
          <p>Odpovídáme za výsledek. Kdy nás oslovit:</p>
          <ul>
            <li>Peníze vynaložené na marketing se jeví jako ztracené</li>
            <li>Nevíte/chcete líp vědět, co ve vašem marketingu funguje a co ne</li>
            <li>Výsledky marketingu jsou za očekáváním</li>
            <li>Řešíte problémy s řízením a hodnocením marketingu</li>
            <li>Chcete začít dělat marketing jinak, systematicky</li>
            <li>Viditelnost a známost vaši firmy nebo produktů na cílovém trhu je malá</li>
            <li>Hledáte pro produkty trhy nebo nové zákazníky</li>
            <li>Marketing je zdrojem napětí ve firmě</li>
          </ul>
        </>
      ),
    },
    buy: {
      icon: faShoppingCart,
      title: 'Nákup a zbožíznalství',
      body: (
        <>
          <p>Provedeme audit používaných materiálů. Nastavíme materiály a jejich kmenová data do účetního systému tak, abychom byli schopni provádět
          analýzy a reporty.</p>
          <ul>
            <li>Společná kultivace pozitivního listu &ndash; seznam používaných materiálů pro dosažení větší efektivity nákupů.</li>
            <li>Společné vyjednávání o cenách a optimalizace nákupů</li>
            <li>Analýza nakupovaných materiálů zpohledu kvality, obrátkovosti a také ABC analýza skladových zásob</li>
            <li>Pravidelné závozy objednaných materiálů dle servisní úrovně, úspora skladovacích ploch a lidských zdrojů</li>
            <li>Možnost zjednodušení evidence DPH pro společnosti pracující s více druhy daně</li>
          </ul>
        </>
      ),
    },
  };

  const [active, setActive] = React.useState(
    location.state ? (location.state.field || 'economy') : 'economy');
  const activeField = fields[active];
  const FieldLink = ({ field, children }) => (
    <li className={active === field ? 'nav-item active' : 'nav-item'}>
      <button onClick={(event) => { setActive(field); }}>{children}</button>
    </li>
  );

  return (
    <>
      <SEO title="Oblasti" />
      <Header />
      <article>
        <section id="oblasti">
          <div className="container grid-lg">
            <div className="columns">
              <div className="column col-6 col-sm-12">
                <h1>Oblasti</h1>
                <ul className="nav text-large">
                  <FieldLink field="economy">Ekonomika</FieldLink>
                  <FieldLink field="controlling">Controlling</FieldLink>
                  <FieldLink field="analysis">Analýza ZP</FieldLink>
                  <FieldLink field="management">Facility management</FieldLink>
                  <FieldLink field="it">IT a aplikační podpora</FieldLink>
                  <FieldLink field="personnel">Mzdy a personalistika</FieldLink>
                  <FieldLink field="marketing">Marketing a podpora prodeje</FieldLink>
                  <FieldLink field="buy">Nákup a zbožíznalství</FieldLink>
                </ul>
              </div>
              <div className="column col-6 col-sm-12">
                <div className={active + ' card'}>
                  <div className="card-image">
                    <FontAwesomeIcon icon={activeField.icon} className="img-responsive" />
                  </div>
                  <div className="card-header">
                    <h4 className="card-title">{activeField.title}</h4>
                  </div>
                  <div className="card-body">
                    {activeField.body}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <Footer />
    </>
  );
};

FieldsPage.defaultProps = {
  location: undefined,
}

export default FieldsPage;
